.adminDashboardScreenPageContainer{
    height: 90%;
    width: 90%;
    background-color: white;
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
   border-radius: 10px;
   overflow-y: auto;
   overflow-x: auto;
}
.registeredScreensTitle{
    font-family: "Inter-Medium";
    font-size: 30px;
    display: block;
    padding: 20px;
}
.adminDashboardScreenPageSearchInput{
    height: 40px;
    width:350px;
    border-radius: 5px;
    border: .5px solid rgb(109, 109, 109);
    font-family: "Inter-Medium";
    font-size: 20px;
    font-weight: 300;
    padding-left: 10px;
    outline: none;
    -moz-appearance: textfield;
    display: block;
    margin-left: 20px;
}