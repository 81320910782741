.screenPfpImg{
    width: 300px;
    height: 300px;
    margin-left: 35px;
}

.screenDetailsScreenDetailsContainer,
.screenDetailsScreenImgContainer {
    width: 50%;
    display: inline-block;
    vertical-align: top;

}
.screenDetailsScreenDetailsContainer{
    height: 300px;
}
.screenDetailsScreenId {
    font-size: 45px;
    font-family: "Inter-Bold";
    vertical-align: top;
    margin-top: 15px;
    display: block;
}
.screenDetailsItem{
    font-family: "Inter-Medium";
    display: block;
    padding: 5px;
    font-size: 18px;
}
.curAdGal{
    margin-left: 20px;
}
.leftChevronScreenDetails{
    height: 50px;
    width: 50px;
    display: block;
    padding: 10px;
}
.leftChevronScreenDetails:hover{
    cursor: pointer;
}
.userDetailsItem{
    width: 45%;
    display: inline-block;
    margin-left: 10px;
}
.userDetailsTitle{
    font-family: "Inter-Medium";
    color: rgb(93, 93, 93);
display: block;

}
.userDetailsData{
    font-family: "Inter-Medium";
display: block;
}
.ApplicationStatus{
    font-family: "Inter-Bold";

}
.tabTitle{
    font-family: "Inter-Bold";

}
.userDetailsContainer{
    padding-left: 10px;
}
.documentsUserDetailsItem{
    display: block;
    width: 100%;
}
.userDetailsBtn {
    display: block;
    border: none;
    outline: none;
    font-family: "Inter-Bold";
    height: 40px;
    min-width: 200px;
    font-size: 15px;
    border-radius: 2px;
    text-align: center;
}
.sendCommentBtn {
background-color: black;
color: white;

margin-bottom: 10px;
}
.approveBtn{
background-color: #dc4f24b4;
border: solid 1px #DC5024;
color: white;
margin-bottom: 10px;
    display: inline-block;

}
.denyBtn{
    background-color: #FFF5F5;
    border: solid 1px #FF0000;
    display: inline-block;
    color: #D23F3F;
    margin-bottom: 10px;
    margin-left: 10px;
}
.userDetailsBtn:hover,
.cancelCommentBtn:hover{
    cursor: pointer;
    opacity: .95;
}
.reopenBtn{
    background-color: #dc4f24b4;
    border: solid 1px #DC5024;
    color: white;
    margin-bottom: 10px;
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;

}
.zelleDetailsBtn{
    background-color: #8856F4;
    color: white;
    margin-bottom: 10px;
    display: inline-block;
    margin-left: 10px;

}
.alert {
    background-color: white;
    width: 400px;
    min-height: 200px;
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    filter: brightness(100%) !important;
    padding: 30px;
}
.cancelCommentBtn{
    transition: opacity ease-in-out 1s;

      margin-top: 10%;
      outline: none;
      background-color: black;
      color: white;
      border: none;
      font-family: 'Inter-Bold';
      font-size: 15px;
      border-radius: 2.5px;
      position: relative;
      left:48%;
      display: block;
      width: 60%;
      transform: translateX(-50%);
      padding: 15px;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;

}
.loader{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
}
.opaqueBackground {
    position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 150vh;
        display: flex;
        justify-content: center;
        align-items: center;
    background-color: rgba(0, 0, 0, 0.507);
    z-index: 1;
}
.commentTItle {
    font-family: "Inter-Medium";
    font-size: 25px;
    display: block;
    padding: 20px;
}
.commentTextArea{
    resize: none;
    height: 300px;
    width: 100%;
    font-family: "Inter-Medium";
    outline: none;
     border: solid 1px black;
     border-radius: 3px;

}
.placeUnderReviewBtn{
    margin-left: 10px;
}
.disabled{
    opacity: .85;
    transition: opacity ease-in-out 1s;
}
.disabled:hover{
    cursor: default;
}
.userDetailsContainer{

width: 98%;
padding: 0;
}
.setUserLocationContainer{
    margin-left: 15px;
    width: 45%;
    display: inline-block;
}
.saveLocationsBtn{
    margin-top: 10px;
    margin-left: 15px;
    display: inline-block;
}
.saveLocationsBtn:hover{
    cursor: pointer;
}
.AdminUserDetailsContainer{
    width: 100%;
}