.adminNav{
    width: 18rem;
    background-color: black;
    height: 100vh;
    color: white;
    display: inline-block;
}
.adminNavTitle{
    font-family: "Inter-Black";
    font-size: 30px;
    display: block;
    text-align: center;
    padding-top: 10px;
}
.adminNavSubTitle{
      font-family: "Inter-SemiBold";
      font-size: 15px;
      display: block;
      text-align: center;
}
.adminpfp{
    height: 40px;
    width: 40px;
    margin: 10px;
    display: inline;
}
.adminUserDetails{
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.adminName{
    display: block;
    font-family: "Inter-Medium";
}
.adminRole{
    display: block;
    font-family: "Inter-Light";
}
.adminNavItemImg{
    height: 40px;
    width: 40px;
    margin: 10px;
}
.adminNavItemTitle{
    font-family: "Inter-Regular";
    font-size: 20px;
     position: relative;
     top: 50%;
     transform: translateY(-50%);
     display: inline-block;
}
.adminNavItem {
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    margin-top: 10px;
    
}
.adminNavItem:hover{
    cursor: pointer;
        background-color: #78787875;

}
.active{
        background-color: #78787875;

}
.logout{
    position: absolute;
bottom: 0;
}
@media (max-width: 801px) {
    .adminNav{
        height: 150px !important;
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }
    .adminProfile{
        display: none;
    }
    .adminNavItemTitle{
        display: none !important;
    }
    .adminNavItem{
        display: inline;
    }
    .adminNav{
        overflow-x: auto;
    }
    .active {
        background-color: transparent !important;

    }
    .active > .adminNavItemImg{
        background-color: #78787875;
        padding: 10px;
        border-radius: 3px;
    }
}