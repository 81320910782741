.loginphoneNumberInput{
     height: 50px;
     width:90%;
     border-radius: 5px;
     border: .5px solid rgb(109, 109, 109);
     font-family: Roboto,
     sans-serif;
     font-size: 20px;
     font-weight: 300;
     padding-left: 10px;
     outline: none;
     -moz-appearance: textfield;
     display: block;
    position: relative;
     left:50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}
.verificationBoxSubTitle{
    font-size: 15px;
}
.loginRingLoaderContainer{
    display: inline-block;
}