.adminDashboardHomeContainer{
    padding-top: 30px;
    position: absolute;
}

.adminDashboardHomeGraph{
    width: 50%;
    display: inline-block;
}
.adminDashboardHomeStatsContainer {
    width: 50%;
    display: inline-block;
    float: right;

}
.adminDashboardHomeAdsDisplayedToday{
    width: 40%;
    display: inline-block;
background-color: #DC5024;
margin-left: 10px;
margin-right: 10px;
padding: 10px;
border-radius: 10px;
height: 100px;
}
.adminDashboardHomeAdsDisplayedTodayTitle,
.adminDashboardHomeAdsDisplayedTodayValue{
    color: white;
    display: block;
}

.adminDashboardHomeAdsDisplayedTodayTitle{
    font-size: 20px;
    font-family: "Inter-Light";

}
.adminDashboardHomeAdsDisplayedTodayValue{
    font-size: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Inter-Medium";


}
.adminDashboardHomeAdsDisplayedValue {
    font-size: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Inter-Medium";
}
.adminDashboardHomeAdsDisplayedTitle {
    font-size: 20px;
    font-family: "Inter-Light";

}
.adminDashboardHomeAdsDisplayedTitle,
.adminDashboardHomeAdsDisplayedValue {
    color: black;
    display: block;
}
.adminDashboardHomeAdsDisplayed {
    width: 30%;
    display: inline-block;
    background-color: #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
height: 100px;

}
.activeScreens{
    background-color: white;
}
.totalScreens{
background-color: #DC5024;
}
.activeScreens, .totalScreens{
    margin-top: 10px;
}
.activeScreens .adminDashboardHomeAdsDisplayedTodayTitle,
.activeScreens .adminDashboardHomeAdsDisplayedTodayValue {
    color: black
}
.totalScreens .adminDashboardHomeAdsDisplayedTitle,
.totalScreens .adminDashboardHomeAdsDisplayedValue {
    color: white;
}
.adminDashboardHomeAdsDisplayedTodayTitleLiveCircle{
    height: 10px;
    width: 10px;
    background-color: #DC5024;
    border-radius: 10px;
    display: inline-block;
    float: right;
    margin-right: 10px;
}

#root>div>div.advertiserDashboardContainer>div.circleGraphContainer>div {
    display: none;
}
@media (max-width: 801px) {
 .adminDashboardHomeStatsContainer{
     width: auto;
 }
}