@font-face {
    font-family: Inter-Black;
    src: url(../../../assets/fonts/Inter-Black.ttf);
}
@font-face {
    font-family: Inter-Bold;
    src: url(../../../assets/fonts/Inter-Bold.ttf);
}
@font-face {
    font-family: Inter-SemiBold;
    src: url(../../../assets/fonts/Inter-SemiBold.ttf);
}
@font-face {
    font-family: Inter-Medium;
    src: url(../../../assets/fonts/Inter-Medium.ttf);
}
@font-face {
    font-family: Inter-Regular;
    src: url(../../../assets/fonts/Inter-Regular.ttf);
}
@font-face {
    font-family: Inter-Light;
    src: url(../../../assets/fonts/Inter-Light.ttf);
}
@font-face {
    font-family: Inter-Thin;
    src: url(../../../assets/fonts/Inter-Thin.ttf);
}
.nav{
    width: 100%;
    height: 80px;
    position: relative;
    top: 0;
    border-bottom   : solid #787878 1px;
}
.contactUs, .title, .placeholder{
    display: inline-block;
}
.contactUs{
    font-family: "Inter-Bold";
    width: 20%;
    text-align: center;
    font-size: 30px;
}
.title {
    font-family: "Inter-Black";
    width: 60%;
    text-align: center;
    border-left: solid #787878 1px;
    border-right: solid #787878 1px;
     font-size:50px;
         -webkit-touch-callout: none;
         /* iOS Safari */
         -webkit-user-select: none;
         /* Safari */
         -khtml-user-select: none;
         /* Konqueror HTML */
         -moz-user-select: none;
         /* Old versions of Firefox */
         -ms-user-select: none;
         /* Internet Explorer/Edge */
         user-select: none;
}
.title:hover{
         cursor: pointer;

}
.placeholder{
    width: 19%;
       font-family: "Inter-Bold";
       text-align: center;
       font-size: 30px;
}
.placeholder:hover{
    cursor: pointer;
}
.registrationContainer{
    width: 100%;
    padding-top: 100px;
}
.invertDisplayImg{
    float: right;
    margin-right: 30px;
    
}
.registrationBlock{
    display: inline-block;
    margin-left: 30px;

}
.registrationTitle{
    font-size: 60px;
    font-family: "Inter-Bold";
    display: block;
}
.mobilePhoneRegistrationInput{
    height: 50px;
    width:350px;
    border-radius: 5px;
    border: .5px solid rgb(109, 109, 109);
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 300;
    padding-left: 10px;
    outline: none;
     -moz-appearance: textfield;
     display: block;
}

.phoneNumberApplyBtn{
    height: 30px;
    width: 175px;
    background-color: #FF8E6A;
    border-radius: 13px;
    border: none;
    color: white;
    font-family: 'Inter-Bold';
     font-size: 20px;
     height:48px;
     margin-top: 10px
}
.phoneNumberApplyBtn:hover{
    cursor: pointer;
    opacity: .9;
}
#agreeTOS{
font-size: 20px;
    font-family: 'Inter-Light';
height: 20px;
width: 20px;

}
.agreeTOSContainer{
    margin-left: 30px;
    display: inline-block;
}
.pNoError{
    display: block;
    font-family: "Inter-Light";
    margin-top: 10px;
    color: red;
}
@media (max-width:706px) {
    .invertDisplayImg {
        width: 70%;
        position: relative;
        left:50%;
        transform: translateX(-50%);
        margin: 30px;
        margin-right: 30%;
    }
    .agreeTOSContainer{
       margin-left: 0;
    }
.registrationBlock{
    margin-left: 10px;
}
}
.mobilenavtitle {
    border: none !important;
    text-align: left !important;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 35px !important;
}
.mobilenav{
    height: 80px !important;
    background-color: white;
}
.mobilenavchevron{
    display: inline-block;
position: absolute !important;
bottom: 0 !important;
right: 0 !important;
}

.chevronIconnav{
    height: 25px;
    margin-bottom: 10px;
    margin-right: 10px;
}
.mobilenavmenuItemTitle{
    font-family: "Inter-Bold";
    font-size: 25px;
    margin: 10px;
}

@keyframes navMenuAnimation {
    from {
position: relative;
top:-100px;
width: 100%;
z-index: -1 !important;
}

    to {
              position: relative;
              top:0;
              width: 100%;

    }
}

.mobilenavmenuItem {
    padding: 10px;
    border-bottom: solid black 1px;
    background-color: white !important;
}

.mobilenavmenu {
    animation-name: navMenuAnimation;
    animation-duration: .5s;
position: absolute;
z-index: 2;
width: 100%;
    background-color: white !important;
}
.contactUs:hover{
    cursor: pointer;
}