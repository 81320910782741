.adminDashboardScreensTable{
    width: 100%;
      border-collapse: collapse;

}
.adminDashboardScreensTable tbody tr td{
    text-align: center;
font-family: "Inter-Regular";
font-size: 20px;
padding: 15px;
}
.adminDashboardScreensTable thead tr th{
font-family: "Inter-Medium";
font-size: 20px;
padding: 15px;
font-weight: 400;
}
.adminDashboardScreensTable>tbody>tr,
.adminDashboardScreensTable thead tr {
border-bottom: solid #9A9A9A 1px;
}
.adminDashboardScreensTable>tbody>tr:hover{
    cursor: pointer;
}
.activeStatus{
    background-color: #c2fbcb85;
    color: #4dc75fd2;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 2px;
}
.inactiveStatus {
    background-color: rgba(199, 77, 77, 0.301);
    color: #c74d4dd8;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 2px;
}
.tablePaginationContainer{
    display: inline;
    float: right;
    margin-top: 10px;
    font-family: "Inter-Regular";
    font-size: 20px;
}
.paginationIcon{
    margin: 10px;
    padding: 5px;
    font-size: 25px;

}
.tablePaginationContainer:hover{
    cursor: default
}
.paginationIcon:hover{
    cursor: pointer;
    background-color: rgb(192, 192, 192);
    border-radius: 20px;
}

.page::-webkit-outer-spin-button,
.page::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.page[type=number] {
    -moz-appearance: textfield;
}
.page{
    width: 20px;
    height: 20px;
    border: none;
    font-size: 15px;

}