
.newCampaignInputTitle{
    font-family: "Inter-Light";
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
}
.newCampaignInputContainer{
    display: block;
    margin-left: 20px;
}
.newCampaignInputTwoContainer{
        display: inline-block;
        margin-left: 20px;
        margin-top: 20px;
        width: 46%;
}
.newCampaignInputInputFull {
        outline: none;

    display: inline;
    border: rgba(0, 0, 0, 0.575) .5px solid;
    width: 90%;
     box-sizing: border-box;
     -webkit-box-sizing:border-box;
     -moz-box-sizing: border-box;
     height: 40px;
     font-size: 20px;
     font-family: "Inter-Light";
}
.newCampaignInputInputFull:hover{
    border: black 1px solid;
}
.newCampaignInputFileUpload::-webkit-file-upload-button {
display: none;
}
.newCampaignInputFileUpload{
    padding-top: 5px;
}
.multiSelectNewCampaignInput{
    width: 90%;
        outline: none;

}
.multiSelectNewCampaignInput .css-13cymwt-control{
     border-radius: 0px;
     border: rgba(0, 0, 0, 0.575) .5px solid !important;
        outline: none;

}


.alert {
    background-color: white;
    width: 400px;
    min-height: 200px;
    max-height: 450px;
    overflow-y: auto;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    filter: brightness(100%) !important;
    padding: 30px;
}

.opaqueBackground,
.loadingOverlay {
position: absolute;
height: 100%;
width: 100%;
background-color: rgba(0, 0, 0, 0.507);
z-index: 1;
}
.loadingOverlay{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 101;

}
.loaderForOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.paymentButton{
    margin-bottom: 0;
}
.paymentCancelButton{
    margin-top: 0;
}
@media (max-width: 801px) {
.newCampaignInputTwoContainer{
  width: 90%;  
}}