.landingPageNav{
    height: 80px;
    padding: 30px;
    padding-bottom: 0;
    background-color: white;
}
*{
    scroll-behavior: smooth;
}
.landingPageNav > img{
    max-height: 100%;
}
.landingPageNavMottoContainer{
    display: inline-block;
    width: 200px;
    margin-left: 20px;
    padding-left: 20px;
    border-left: solid black 1px;

}
.landingPageNavMotto{
    font-family: "Inter-Light";
}
.landingPageNavButton{
    float: right;
    font-family: "Inter-Bold";
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border: solid black 2px;
    font-size: 18px;
    margin-right: 30px;
}
.landingPageNavButton:hover {
cursor:  pointer;
}
.landingPageCenterMessage{
    display: block;
    text-align: center;
    font-family: "Inter-Black";
    font-size: 100px;
    line-height: 170px;
    color: white;
}
.landingPageCenterSubMessage{
       display: block;
       text-align: center;
       font-family: "Inter-Light";
       font-size: 30px;
    line-height: 50px;
    color: white;


}
.opportunitiesButtonsContainer{
    text-align: center;
    display: block;
    margin-top:40px;
}
.opportunitiesButtonLearnMore{
     font-family: "Inter-Bold";
     padding: 15px;
     padding-left: 60px;
     padding-right: 60px;
     border: solid rgb(0, 0, 0) 2px;
     font-size: 18px;
     margin-right: 30px;
     color: black;
     background-color: white;
}
.opportunitiesButtonLearnMore:hover {
    cursor:  pointer;
}
.opportunitiesButtonPartner{
       font-family: "Inter-Bold";
       padding: 18px;
       padding-left: 60px;
       padding-right: 60px;
       font-size: 18px;
       margin-right: 30px;
       background-color: #DC5024;
       color: white;
       border-radius: 5px;
}
.opportunitiesButtonPartner:hover {
    cursor: pointer;
}
.moreInfo1{
    background-color: #DC5024;
    color: white;
    margin-top: 100px;

    padding: 60px;
}
.moreInfo2 {
    background-color: #ffffff;
    color: black;

    padding: 60px;
}
.moreInfoTitle{
    display: block;
    font-size: 50px;
    font-family: "Inter-Black";
}
.moreInfoText{
    display: block;
    font-family: "Inter-SemiBold";
    font-size: 27px;
    margin-top: 20px;
}
.moreInfoButtonGetStarted{
     font-family: "Inter-Bold";
     padding: 18px;
     padding-left: 60px;
     padding-right: 60px;
     font-size: 18px;
     margin-right: 30px;
     background-color: #ffffff;
     color: black;
     border-radius: 5px;
     
}
.moreInfo2>.gettingStartedButtonContainer >.moreInfoButtonGetStarted {
    border: solid black 2px;
}
.moreInfoButtonGetStarted:hover{
    cursor: pointer;
}
.gettingStartedButtonContainer{
    margin-top: 20px;
    text-align: center;
}
.moreInfoList >li{
    margin-top: 15px;
}
.landingPageVideo{
    width: 100%;
    position: absolute;
    z-index: -1;
    filter: brightness(60%);
    background-color: black;
}
.landingPageFirstContainer{
width: 100%;
}
.opportunitiesButtonContainer{
    display: inline-block;
}
@media(max-width: 1091px){

.landingPageVideo {
    /* position: fixed; */
    min-height: 200vh;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
    overflow: hidden;
}
.landingPageCenterMessage{
    margin-left: 30px;
    margin-right: 30px;
    font-size: 70px;
}

.landingPageFirstContainer {
 overflow: hidden;
}
.opportunitiesButtonContainer {
    display: block;
    margin: 50px;
}
.landingPageNav{
    height: auto;
    clear: both;
    margin: 0px;
    overflow: auto;
    width: 100%;
    padding: 0;
}
.opportunitiesButtonPartner{
    display: block;
}
}