.nav {
    width: 100%;
    height: 80px;
    position: relative;
    top: 0;
    border-bottom: solid #787878 1px;
}

.contactUs,
.title {
    display: inline-block;
}

.contactUs {
    font-family: "Inter-Bold";
    width: 20%;
    text-align: center;
    font-size: 30px;
}

.title {
    font-family: "Inter-Black";
    width: 60%;
    text-align: center;
    border-left: solid #787878 1px;
    border-right: solid #787878 1px;
    font-size: 50px;
}

.contactUsPageContainer{
   height: calc(100% - 80px);
   width: calc(100%);
   margin: 0;
   padding: 0;
  position: fixed;
    background: white;
overflow: auto;
}
.contactUsBox{
   
    background-color: white;
   position: absolute;
   top:50%;
right: 10px;
   transform: translateY(-50%);
   border-radius: 5px;
   border: solid black 1px;
}
.contactUsSpinnerContainer{
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
}
@media (max-width: 672px) {
    .contactUsBox{
       display: none;
    }
    .contactUsIframe{
        display: block;
    }
}