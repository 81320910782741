.advertiserDashboardContainerCampaigns {
    display: inline-block;
    position: absolute;
    height: 100vh;
    width: calc(100% - 18rem);
    background-color: #F5F5F5;
    overflow-y: auto;
}
@media (max-width: 801px) {
.advertiserDashboardContainerCampaigns {

    width: 100%;
        height: calc(100% - 150px);

}
}