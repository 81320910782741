.addAdvertiser{
    display: inline-block;
    float: right;
    margin: 20px;
    margin-top: 30px;
    outline: none;
    background-color: black;
    width: 80px;
    height: 32px;
    color: white;
    border: none;
    font-family: 'Inter-Bold';
    font-size: 20px;
    border-radius: 2.5px;
}
.advertiserListTitle{
    display: inline-block !important;
}
.addAdvertiser:hover {
    cursor: pointer;
    opacity: .9;
}