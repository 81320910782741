.todoPageNavTitle {
    font-family: 'Inter-Black';
    color: #FFBCA7;
    font-size: 35px;
    padding: 10px;
    padding-top: 15px;
    display: inline-block;

}
.nav{
    width: 100%;
    height: 80px;
    position: relative;
    top: 0;
    border-bottom   : solid #787878 1px;
}
.todoPageNavLogoutContainer{
    display: inline-block;
    float: right;
     margin: 20px;
    margin-top: 15px;
    border: solid 3px black;
    border-radius: 5px;
    transition: ease-in-out all .1s;
padding: 5px;
}
.todoPageNavLogout{
    
    height: 35px;
    width: 35px;
    display: inline-block;

    vertical-align: middle;

}
.todoNavLogoutText{
    display: inline-block;
    vertical-align: middle;
    font-family: "Inter-Black";
    font-size: 25px;
    margin-right: 10px;
}
.todoPageNavLogoutContainer:hover {
    box-shadow: 0px 0px 10px -3px rgb(0 0 0 / 75%);
    cursor: pointer;
}
