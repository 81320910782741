.verificationContainer{
    background: linear-gradient(76.99deg, #C52F00 0.22%, #FFBCA7 131.92%);
    height: 100vh;
    width: calc(100%);
}
.verificationBox{
 min-height: 60%;
 width: 25%;
 background-color: white;
 position: absolute;
 top:50%;
 left:50%;
 transform: translate(-50%, -50%);
 border-radius: 10px;
 overflow-y: auto;
 overflow-x: auto;
}
.loginBox {
    width: 22%;
    height: 55%;
}
.verificationBoxLogo{
    font-family: "Inter-Black";
    font-size: 35px;
    margin: 20px;
    display: block;
}
.verificationBoxTitle{
      font-family: "Inter-Medium";
      font-size: 35px;
      margin: 20px;
      margin-top: 60px;
    display: block;

}
.verificationBoxSubTitle{
     font-family: "Inter-Regular";
     font-size: 25px;
     margin: 20px;
     display: block;
}
.verificationInput {
    height: 50px;
    width: 90%;
    border-radius: 5px;
    border: .5px solid rgb(109, 109, 109);
    font-family: sans-serif;
    font-size: 35px;
    font-weight: 300;
    padding-left: 10px;
    outline: none;
    -moz-appearance: textfield;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
       position: relative;
       left:50%;
       transform: translateX(-50%);
       letter-spacing: 10px;
}
.verificationInput::placeholder{
    font-size: 50px;
       letter-spacing: 0;

}
.verificationInput::-webkit-outer-spin-button,
.verificationInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.verificationInput[type=number] {
    -moz-appearance: textfield;
}
.verificationBtn {
    height: 30px;
    width: 175px;
    background-color: #FF8E6A;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: 'Inter-Black';
    font-size: 30px;
    height: 48px;
    position: relative;
    display: block;
    left:50%;
    transform: translateX(-50%);
}
.verificationBtn:hover {
    cursor: pointer;
    opacity: .9;
}
.errorPhoneVerification{
     font-family: "Inter-Light";
     color: red;
     font-size: 15px;
     margin-left: 20px;
     margin-bottom: 20px;
     display: block;
}
.resendButton{
    display: block;
    text-decoration: underline;
    font-family: 'Inter-Light';
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

}
.resendButton:hover{
    cursor: pointer;
    color: #C52F00;
}
.disabledResendLink:hover{
  cursor: default;
  color: black;
}
@media (max-width: 1386px) {
.verificationBox {
    width: 50%;
}

}
@media (max-width: 827px) {
    .verificationBox {
        width: 90%;
    }
}
