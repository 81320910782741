.loginBG{
    display: inline-block;

height: 100vh;
float: right;
position: fixed;
right: 0;
}
.adminLoginContainer{
position: absolute;
height: 100%;
width: 100%;
}
.loginContainer{
    display: inline-block;

    max-width: 35%;
   position: relative;
   top:50%;
   transform: translateY(-50%);
   height: 60%;
   margin-left: 50px;
}
.adminLoginTitle{
    display: block;
    font-family: "Inter-Bold";
    font-size: 60px;
    margin-bottom: 20px;
}
.adminLoginDescription{
     display: block;
     font-family: "Inter-Light";
     font-size: 25px;
    margin-bottom: 30px;
    width:80%;
}
.adminLoginPhoneInput{
    width: 60%;
      outline: none;
      -moz-appearance: textfield;
        border: .5px solid rgb(109, 109, 109);
        height: 50px;
        border-radius: 45px;
        padding-left: 10px;
        font-family: "Inter-Regular";
        font-size: 20px;
        margin-bottom: 30px;
}
.adminLoginBtn{
     height: 48px;
     width: 175px;
     background-color: #FF8E6A;
     border-radius: 48px;
     border: none;
     color: white;
     font-family: 'Inter-Bold';
     font-size: 20px;
     position: relative;
     left: 50%;
     top: 10%;
     transform: translateX(-50%);
     display: block;
}
.adminLoginUnderText{
    font-family: "Inter-Medium";
    display: block;
    margin-top: 30px;
}
.adminLoginUnderItemsContainer{
    position: relative;
        top: 10%;
    width: 60%;

}
.adminLoginBtn:hover {
    cursor: pointer;
    opacity: .8;
}

@media (max-width: 1590px) and
(max-height: 1590px) {
    .loginBG{
        max-width: 100%;
        max-height: 400px;
        border-radius: 15px;
        position: relative;
        bottom: 0;
        left: 50%;
        float: none;
        transform: translateX(-50%)
    }
    .adminLoginTitle{
        width: 100%;
        text-align: center;
        font-size: 50px;

    }
    .loginContainer{
        width: 100% !important;
        max-width: 100% !important;
        margin: 0;
        height: 80%;
    }
    .adminLoginDescription{
        text-align: center;
        margin: 0;
        width: 100%;
    }
    .adminLoginDescription {
        visibility: hidden;
        position: relative;
    }
    .adminLoginDescription:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
        font-size: 25px;
        content: "Welcome back!";
    }
    .adminLoginInputs{
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        width: 80%;
    }
    .adminLoginPhoneInput {
        width: 100%;
    }
    .adminLoginBtn{
        display: block;
        width: 100%;
        position: relative;
        left: 80%;
        transform: translateX(-50%);
    }
}
@media (max-height: 585px) {
    .loginBG{
        display: none;
    }
}