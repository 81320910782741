.advertiserDashboardHometitle{
    font-size: 45px;
    display: block;
    font-family: "Inter-Bold";
    margin: 15px;
    margin-left: 30px;
}
.atAGlanceItemsContainer {
 display:flex;
 margin: 0;
padding-left: 15px;
}
.atAGlanceImpressions{
    height: 166px;
    width: 253px;
    background-color: #dc4f24d7;
    display: inline-block;
    border-radius: 5px;
    margin-right: 10px;
}
.atAGlanceTodayImpressions {
    height: 166px;
    width: 253px;
    background-color: #ffffffd7;
    display: inline-block;
    border-radius: 5px;
    position: relative;
    margin-right: 10px;
}
#borderRight {
    border-right: 1px solid #000000;
    position: absolute;
    top: 15%;
    bottom: 15%;
    right :0;
}
.atAGlanceTodayImpressionsTitle{
 font-size: 20px;
 color: rgb(0, 0, 0);
 font-family: "Inter-Light";
 margin: 10px;
 display: block;
}
.atAGlanceTodayImpressionsCount {
    font-size: 45px;
    color: black;
    font-family: "Inter-Bold";
    margin: 10px;
    display: block;
}
.atAGlanceImpressionsTitle{
    font-size: 20px;
    color: white;
    font-family: "Inter-Light";
    margin: 10px;
    display: block;
}
.atAGlanceImpressionsCount{
    font-size: 45px;
    color: white;
    font-family: "Inter-Bold";
    margin: 10px;
    display: block;
}
.atAGlanceCampaigns{
    height: 200px;
    width: 300px;
    background-color: #ffffff;
    display: inline-block;
    margin-left: 10px;
}
.atAGlanceCampaignsTitle{
     font-size: 20px;
     color: black;
     font-family: "Inter-Medium";
     margin: 10px;
     display: block;
}
.atAGlanceCampaignsCount{
       font-size: 45px;
       color: black;
       font-family: "Inter-Medium";
       margin: 10px;
       display: block;
}
.topRankingCampaignsContainer{
    display: block;
    margin: 15px;
}
.topRankingCampaignsItem{
    display: block;
    background-color: white;
    height: 75px;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 20px;
}
.topRankingCampaignsTitle{
    font-family: "Inter-Thin";
    font-size: 45px;
}
.topRankingCampaignsItemImage{
    margin-left: 10px;
    margin-top: 10px;
    display: inline;
}
.topRankingCampaignsItemTitle,
.topRankingCampaignsItemImpressions,
.topRankingCampaignsItemImpressionsRemaining {
font-family: "Inter-Regular";
font-size: 20px;
display: inline-block;
margin: 10px;
}
.topRankingCampaignsItemTitle{
    margin-left: 40px;
}
.topRankingCampaignsItemImpressionsRemaining{
    float: right
}
.topRankingCampaignsItemImpressions{
    position: relative;
    left: 35%;
    transform: translateX(-50%);
}
.topRankingCampaignsTitlesContainer{
position: relative;
transform: translateY(-50%);
width: 90%;
float: right;
margin-top: 35px;
}
.topCampaignsContainer{
    width: 48.5%;
    margin: 15px;
    border: solid black 1px;
    border-radius: 5px;
    padding:5px;
    padding-top: 15px;
    overflow-x: auto;
}
.topCampaignsTitle{
    font-family: "Inter-SemiBold";
    font-size: 20px;

}
.topCampaignsSubTitle{
    font-family: "Inter-Regular";
    font-size: 15px;
    float: right;
}

.topCampaignsContainer{
    position: relative;
width: 60%;
}
.topCampaignsTable>thead >tr>th{

    font-size: 20px;
    font-family: "Inter-Medium";
    font-weight: 100;
       padding-left: 50px;
       padding-right: 50px;
}
.topCampaignsTable>tbody>tr>td {

    font-size: 20px;
    font-family: "Inter-Regular";
    font-weight: 100;
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
}
.topCampaignsTable>tbody>tr>td {
    border-top: solid 1px black;
}
.topCampaignsSubTitle:hover{
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 800px) {
    .topCampaignsContainer {
        width :  90%;
    }
    .atAGlanceItemsContainer{
        overflow-x: auto;
        display: block;
       margin-left: 15%;
    }
}