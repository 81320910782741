.todoPageNavTitle{
    font-family: 'Inter-Black';
    color: #FFBCA7;
    font-size: 35px;
    padding: 10px;
    padding-top: 15px;
}
.todoContainer{
     min-height: 80%;
     width: 50%;
    position: absolute;
    margin-top: 50px;
    left:50%;
    transform: translateX(-50%);
}
.todoTitle{
    font-family: 'Inter-Bold';
    font-size: 25px;
}
.itemCounttodo{
    display: inline;
    margin-left: 10px;
    color: #3E3E3E;
    font-family: "Inter-Bold";
    background-color: #D9D9D9;
    border-radius: 5px;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    
}

.todoTitleContainer{
    height: 50px;
    padding-left: 45px;
}
.notDoneItems{
    width: 100%;
    height: calc(70vh - 50px);
    overflow-y: auto;
}
.todoItem{
    width: 90%;
    height: 100px;
    border-radius: 10px;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
margin-bottom: 30px;
position: relative;
left:50%;
transform: translateX(-50%);
padding: 10px;
transition: all 200ms ease-in-out;
}
.todoItem:hover{
    cursor: pointer;
box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 24px 10px;

}
@media (max-width: 1386px) {
    .todoContainer{
        width: 80%;
    }
}
.todoItemImage {

    height: 50px;
    width: 50px;

}

.todoItemTitle {
    font-family: "Inter-Bold";
    display: block;
    font-size: 24px;
}

.todoItemSubTitle {
    font-family: "Inter-Light";
    display: block;
    font-size: 20px;

}

.todoItemTitleContainer {
    display: inline-block;
    margin-left: 15px;
}
.todoItemItems{
    position: relative;
    top:50%;
    transform: translateY(-50%);
}
.todoPageNavTitle:hover{
    cursor: pointer;
     -webkit-touch-callout: none;
     /* iOS Safari */
     -webkit-user-select: none;
     /* Safari */
     -khtml-user-select: none;
     /* Konqueror HTML */
     -moz-user-select: none;
     /* Old versions of Firefox */
     -ms-user-select: none;
     /* Internet Explorer/Edge */
     user-select: none;
}
.disabledImgtodo{
    filter: grayscale(100%);
}
.disabledTitle{
    color: #5E5D5D;
}
.downArrowtodo{
    position: relative;
    left:50%;
    margin-top: -80px;

    transform: translateX(-50%);
      height: 85px;
      width: 85px;
      z-index: 2;
}
.downArrowTodoContainer{
    width: 100%;
}

@media (max-width:715px) {
    .downArrowtodo {
        margin-top: -50px;
    }
.todoItemTitle{
    font-size: 3.5vw;
}
.todoItemSubTitle{
    font-size: 2.5vw;

}
}
.disabledtodoItem:hover {
    cursor: default;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}
.zelleEnabled{
    fill: #8856F4;
}
.todoPageLoader {
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
}
@media (max-width:887px) {
    .todoContainer {
        min-height: 90%;
        width: 100% !important;
    }
}