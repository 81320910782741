.todoContainer {
    min-height: 80%;
    width: 50%;
    position: absolute;
    margin-top: 50px;
    left: 50%;
    transform: translateX(-50%);
}
.userDashboardTitle{
    font-family: "Inter-Black";
    font-size: 50px;
    display: block;
    margin-left: 10px;
    margin-top: 10px;
}
.screenContainer{
    width: 98%;
    border: solid black 3px;
    border-radius: 5px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    padding: 10px;
    transition: ease-in-out .25s;

}
.userDashboardScreenId{
     font-family: "Inter-Black";
     font-size: 35px;
     display: block;
     margin-bottom: 50px;
}
.userDashboardScreenDetail{
     font-family: "Inter-Medium";

     font-size: 35px;
     display: block;
     margin-top: 20px;
}
.addButtonText{
    font-size: 35px;
    font-family: "Inter-Bold";
    display: block;
    text-align: center;
    color: white;
}
.addScreenButton {
    background: linear-gradient(76.99deg, #C52F00 0.22%, #FFBCA7 131.92%);
    transition: ease-in-out .25s;
}
.addScreenButton:hover,
.screenContainer:hover{
    cursor: pointer;
    box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 75%);
}