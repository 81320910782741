.gaugeChart{
    font-family: "IBM-Bold";
    width: 100%;
    float: left;
}
.gaugeChartLabelPercent{
    color: black;
}
.gaugeLabelContainer{
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
}
@font-face {
    font-family: IBM-Bold;
    src: url(../../../../../assets/fonts/IBMPlexSans-Bold.ttf);
}
.testLabel{
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
   z-index: 10;
}

.circleGraphContainer {
    display: inline-block;
    position: fixed;
    right: 0;
    z-index: 10;
}
