.adminDashboardContainer {
    display:inline-block;
    position: absolute;
    height: 100vh;
    width: calc(100% - 18rem);
    background-color: #F5F5F5;
    overflow-y: auto;
}
@media (max-width: 801px) {
.adminDashboardContainer {
    width: calc(100%);
}
}