.fileUploaderContainerTitle{
    font-family: "Inter-Regular";
    font-size: 18px;
}
.uploadOptionsContainer{
    margin-top: 5%;
}
.fileUploaderContainerButton{
    float: right;
    font-family: "Inter-Bold";
    font-size: 18px;
    border: solid 1px black;
    border-radius: 45px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.fileUploaderContainerButton:hover{
    cursor: pointer;
}
.fileUploaderContainer{
    border-bottom: solid black 1px;
    padding-bottom: 40px;
    margin-top: 40px;
}
.titleandfilenamecontainer{
    display: inline-block;
}
.fileNameOnUpload{
    display: block;
    max-width: 100%;
      text-overflow: ellipsis;

}