.linkNewScreenContainer{
    height: 100vh;
    width: 100%;
    background-color: #DC5024;
    overflow-y:auto ;
}
.linkNewScreenTitle{
    color: white;
    font-family: "Inter-Black";
    font-size:60px;
    padding-left: 10%;
    margin: 0;

}
.stepsContainer{
    font-family: "Inter-Medium";
    font-size: 40px;
    color: white;
    padding-left: 10%;
    position: relative;
    top: 30px;
    margin: 0;
}
.stepsList{
    margin: 0;

}
.screenCode{
    background-color: #FF8E6A;
    font-family: "Inter-Black";
    font-size: 25px;
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10%;
    outline: none;
    height: 60px;
    border-radius: 5px;
    color: white;
    text-align: center;
}
.screenCode:hover{
    cursor: text;
}
.screenCode::placeholder{
    color: white;
}
.navlinkNew{
    border-bottom: none;
}
.navlinkNew .todoPageNavTitle{
font-size: 30px;
}
.searchButton{
    color: white;
    text-align: center;
    font-size: 25px;
    font-family: "Inter-Bold";
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13%;
    display: block;
}
.searchButton:hover{
    cursor: pointer;
    color: #ffffffbe;
}
.backButton{
    color: white;
    text-align: center;
    text-decoration: underline;
    font-size: 20px;
    font-family: "Inter-Medium";
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15%;
    display: block;
}
.backButton:hover{
    cursor: pointer;
    color: #ffffffbe;

}
.errorText{
    color: white;
    text-align: center;
    font-size: 20px;
    font-family: "Inter-Light";
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15%;
    display: block;
}
.searchingSpinner{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13%;

}