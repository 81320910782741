.businessDetailsTitle {
    font-size: 30px;
    display: block;
    text-align: center;
    font-family: 'Inter-Medium';
    margin-top: 50px;
}
.businessDetailsContainer{
    width: 60%;
    min-height: 80%;
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
}
.businessInfoNameInput{
    height: 50px;
    width:100%;
    border-radius: 5px;
    border: .5px solid rgb(109, 109, 109);
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 300;
    padding-left: 10px;
    outline: none;
    -moz-appearance: textfield;
    display: inline-block;
       box-sizing: border-box;

}
.businessInfoCityInput{
        height: 50px;
        width: 100%;
        border-radius: 5px;
        border: .5px solid rgb(109, 109, 109);
        font-family: sans-serif;
        font-size: 20px;
        font-weight: 300;
        padding-left: 10px;
        outline: none;
        -moz-appearance: textfield;
       box-sizing: border-box;
}


.businessInfoInputContainer{
width:30%;
        margin-top: 40px;
display: inline-block;
        margin-right: 3%;

}
.last{
    float:right;
    margin-right: 0;
}
.middle{
     margin-left:25px;
}
.labelForBusinessInput{
    font-family: "Inter-Medium";
}

.businessInfoTopInputContainer{
    margin-top: 40px;

}
@media (max-width:925px) {
    .businessDetailsContainer{
        width: 80%;
    }
}
@media(max-width: 689px){
    .businessInfoInputContainer  {
        width: 100%;
    }
.middle {
    position: relative;
     margin-left:0;
    }
}
@media (max-height: 690px) {
 .businessDetailsContainer {
     height: calc(100%-81px);
     margin-top: 81px;
     overflow-y:auto ;
 }
}
.businessDetailsSave {
    height: 30px;
    width: 175px;
    background-color: #FF8E6A;
    border-radius: 13px;
    border: none;
    color: white;
    font-family: 'Inter-Bold';
    font-size: 20px;
    height: 48px;
    margin-top: 40px;
    position: relative;
    left:50%;
    transform: translateX(-50%);
}

.businessDetailsSave:hover {
    cursor: pointer;
    opacity: .9;
}
.disabled{
    opacity: .7 !important;

}
.disabled:hover{
    cursor: default
}
.setSameAsAcc{
    font-size: 20px;
    font-family: "Inter-Light";
}
.zelleSetSameContainer{
    margin-left: 0;
}
.zelleEmailInput{
    margin-top: 20px;
}
.businessDetailsSubTitle{
    /* opacity: .75; */
    font-family: "Inter-Light";
    display: block;
    text-align: center;
}