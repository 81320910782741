.underReviewMessageContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.underReviewMessageTitle{
    font-family: "Inter-Medium";
    font-size: 25px;
    display: block;
    text-align: center;
margin-bottom: 10px;
}
.underReviewMessage{
    font-family: "Inter-Light";
    font-size: 20px;
    display: block; 
    text-align: center;
}
.underReviewHelpFooter{
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;

    font-family: "Inter-Medium";
}
.emailInMessage{
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    background-color: rgb(202, 202, 202);
    border-radius: 5px;
    padding: 2px;
    color: rgb(43, 43, 43);
}