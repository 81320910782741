.advertismentCampaignDetailsTitle {
    display: block;
    margin: 10px;
    margin-left: 15px;
    font-family: 'Inter-Medium';
    font-size: 30px;
}

.advertismentCampaignDetailsSubTitle {
    display: block;
    font-family: "Inter-Bold";
    font-size: 27px;
    width: 100%;
}

.advertismentCampaignDetailsHalfContainer {
    display: inline-block;
    width: 45%;
    height: 250px;
    padding-left: 15px;

}

.advertismentCampaignDetailsFullContainer {
    display: block;
    height: 200px;
    padding-left: 15px;
    margin-top: 30px;
    width: 90%;
}

.advertismentImage {
    box-sizing: border-box;
    max-width: 45%;
    max-height: 80%;

    margin-right: 15px;
    border-radius: 5px;

}

.advertismentDetailsContentAndImpressionsContainer {
    display: inline-flex;
    width: 100%;
}

.advertismentStatsInnerContainer {
    display: inline-flex;
}

.advertismentStatsInnerContainer {
    display: inline-flex;
    width: 100%;
}

.advertiserCampaignDetailsLocationsContainers {
    background-color: rgb(199, 199, 199);
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
    font-family: "Inter-Light";
    display: inline-block;
    margin-top: -5px;
    border: solid 2px white;
}

.advertismentCampaignDataTitle {
    font-family: "Inter-Regular";
    display: block;
    width: 50%;
}

.userDetailsBtn {
    display: block;
    border: none;
    outline: none;
    font-family: "Inter-Bold";
    height: 40px;
    min-width: 200px;
    font-size: 15px;
    border-radius: 2px;
    text-align: center;
}

.userDetailsBtn:hover {
    cursor: pointer;
    opacity: .95;
}

.denyBtnCampaignDetails {
    background-color: #FFF5F5;
    border: solid 1px #FF0000;
    display: block;
    color: #D23F3F;
    margin-top: 10px;
}