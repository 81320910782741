@font-face {
    font-family: Inter-Black;
    src: url(../../../assets/fonts/Inter-Black.ttf);
}

@font-face {
    font-family: Inter-Bold;
    src: url(../../../assets/fonts/Inter-Bold.ttf);
}

@font-face {
    font-family: Inter-Light;
    src: url(../../../assets/fonts/Inter-Light.ttf);
}
.contactUs:hover {
    cursor: pointer;
}
.nav {
    width: 100%;
    height: 80px;
    position: relative;
    top: 0;
    border-bottom: solid #787878 1px;
}

.contactUs,
.title {
    display: inline-block;
}

.contactUs {
    font-family: "Inter-Bold";
    width: 20%;
    text-align: center;
    font-size: 30px;
}

.title {
    font-family: "Inter-Black";
    width: 60%;
    text-align: center;
    border-left: solid #787878 1px;
    border-right: solid #787878 1px;
    font-size: 50px;
}


.registrationSecondContainer{
    width: 90%;
    height: 80%;
   position: absolute;
   top:55%;
   left:50%;
   transform: translate(-50%, -50%);
}
.registrationSecondTitle{
    font-size: 60px;
    font-family: "Inter-Bold";
    display: block;
}
.registrationSecondInput{
     height: 50px;
     width:45%;
     border-radius: 5px;
     border: .5px solid rgb(109, 109, 109);
     font-family: sans-serif;
     font-size: 20px;
     font-weight: 300;
     padding-left: 10px;
     outline: none;
     -moz-appearance: textfield;
     display: inline-block;
     margin: 20px;
}
.registerSecondApplyBtn{
     height: 30px;
     width: 175px;
     background-color: #FF8E6A;
     border-radius: 13px;
     border: none;
     color: white;
     font-family: 'Inter-Bold';
     font-size: 20px;
     height:48px;
     margin-left: 20px;
}
.registerSecondApplyBtn:hover {
    cursor: pointer;
    opacity: .9;
}
@media (max-width: 770px) {
    .registrationSecondInput {
     width:80%;

    }
}