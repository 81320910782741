.graphTitle{
    font-family: "Inter-Light";
    display:block;
}
.graphSubTitle {
    font-family: "Inter-Light";
    display: block;
    opacity: .75;
}
.graphContainer{
    border: solid 1px black;
    border-radius: 5px;
    display: inline-block;
    margin: 15px;
    padding: 10px;
}
@media (max-width: 800px) {
    .graphContainer{
        display: none;
    }
}