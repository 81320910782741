.userDashboardScreenInfoQuickStats{
    display: block;
    height: 12em;
    margin-top: 30px;
padding-left: 10px;
}
.userDashboardScreenInfoQuickStatsTotalRev, .userDashboardScreenInfoQuickStatsTotalPaid, .userDashboardScreenInfoQuickStatsTotalHrsActive, .userDashboardScreenInfoQuickStatsTotalLastActive{
    background-color: white;
    border: solid 1px black;
    border-radius: 3px;
    width: 20%;
    height: 90%;
    display: inline-block;
    margin-top: 10px;
    vertical-align: top;
}
.userDashboardScreenInfoQuickStatsTotalPaid, .userDashboardScreenInfoQuickStatsTotalHrsActive, .userDashboardScreenInfoQuickStatsTotalLastActive{
    margin-left: 10px;
}
.userDashboardScreenInfoQuickStatsTotalLastActive{
    width: 35%;

}
.userDashboardScreenInfoQuickStatsTitle{
display: block;
    font-family: "Inter-Light";
    font-size: 17px;
    margin: 10px;
}
.userDashboardScreenInfoQuickStatsContent{
    display: block;
    font-family: "Inter-SemiBold";
    font-size: 45px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: 25%;
    transform: translateY(-50%);
    text-align: left;
}
.userDashboardScreenInfoActivityGraphTitle{
    font-family: "Inter-Thin";
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;

    font-size: 45px;
}
.userDashboardScreenInfoActivityGraph{
    display: block;
    height: 25em;
    background-color: black;
}
.userDashboardScreenInfoContent{
    display: inline-block;
    height: 12em;
    border: solid 1px black;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    vertical-align: top;
    width: 60%;
    overflow-x: auto;
    overflow-y: hidden;
}
.userDashboardScreenInfoContentImage{
    display: inline-block;
    max-height: 100%;
    max-width: 25%;
    margin-right: 10px;
}
.userDashboardScreenInfoContentImage:hover{
    cursor: pointer;
}
.userDashboardScreenInfoContentImageLast{
    margin-right: 0;

}
.userDashboardScreenInfoContentEmptyContent{
       display: inline-block;
    height: 100%;
    width: 22%;
    margin-right: 10px;
    border: dashed 1px black;
    border-radius: 5px;
    vertical-align: top;
position: relative;
}
.userDashboardScreenInfoContentEmptyContent:hover{
    cursor: pointer;
}
.userDashboardScreenInfoContentEmptyContentPlusText{
 position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

}
.userDashboardScreenInfoContentEmptyContentPlus{
   
    font-family: "Roboto";
    font-size: 60px;
    color: grey;
    text-align: center;

}
.userDashboardScreenInfoContentEmptyContentText{
       font-family: "Inter-Regular";
    font-size: 20px;
    color: grey;
    display: block;
    text-align: center;

}
@media (max-width: 790px) {
    .userDashboardScreenInfoQuickStats{
        overflow-y: auto;
    }
    .userDashboardScreenInfoQuickStatsContent{
        font-size: 5vw;
    }
.userDashboardScreenInfoQuickStatsTotalRev, .userDashboardScreenInfoQuickStatsTotalPaid, .userDashboardScreenInfoQuickStatsTotalHrsActive, .userDashboardScreenInfoQuickStatsTotalLastActive{
    display: block;
    width: 90%;
    margin-left: 10px;

}
.userDashboardScreenInfoContent{
    width: 90%;
    overflow-y: auto;
}
.userDashboardScreenInfoContentEmptyContent{
    width: 90%;
    margin: 10px;
}
.userDashboardScreenInfoContentImage{
    display: block;
    margin: 10px;
    position: relative;
    left: 35%;
}
}.toggleButton:hover{
cursor: pointer;
}
.bestSizeMessage{
    display: block;
    font-family: "Inter-Regular";
    text-align: center;
}