.advertiserDashboardContainer {
    display:inline-block;
    position: absolute;
        height: 100vh;
    width: calc(100% - 18rem);
    background-color: #ffffff;
    overflow-y: auto;
}
#root>div>div.advertiserDashboardContainer>div.circleGraphContainer>div {
    display: none;
}
@media (max-width: 801px) {
    .advertiserDashboardContainer {
        width: calc(100%);
        height: calc(100% - 150px) ;
    }
}