.graphTitle {
    font-family: "Inter-Light";
    display: block;
}

.graphSubTitle {
    font-family: "Inter-Light";
    display: block;
    opacity: .75;
}

.graphContainerDetails {
    border: solid 1px black;
    border-radius: 5px;
    display: inline-block;
    margin: 15px;
    padding: 10px;
    height: 80%;
    width: 100%;

}