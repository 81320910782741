.advertiserRegisterContainer{
  display: block;
padding-left: 20px;
}
.advertiserRegisterItemfull,
.advertiserRegisterItemTitle {
    width: 95%;
}
.advertiserRegisterItemfull, .advertiserRegisterItemhalfleft,
.advertiserRegisterItemhalfright {
    margin-top: 15px;
}
.advertiserRegisterItemhalfright{
    display: inline-block;
    width: 45%;
    margin-right: 2.5%;
}
.advertiserRegisterItemhalfleft {
    display: inline-block;
    width: 45%;
    margin-left: 2.5%;
}

.advertiserRegisterItemTitle{
    font-family: "Inter-Medium";
    font-size: 20px;
    margin-bottom: 10px;
}
.advertiserRegisterItemInput{
         height: 40px;
         width:100%;
         margin-right:0;
         border-radius: 5px;
         border: 1px solid rgb(51, 51, 51);
         font-family: Roboto,
         sans-serif;
         font-size: 20px;
         font-weight: 300;
         padding-left: 10px;
         outline: none;
         -moz-appearance: textfield;
         display: block;
         position: relative;
}
.advertiserRegisterSubmitBtn{
    margin-top: 10%;
    outline: none;
    background-color: black;
    color: white;
    border: none;
    font-family: 'Inter-Bold';
    font-size: 15px;
    border-radius: 2.5px;
    position: relative;
    left:48%;
    transform: translateX(-50%);
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}
.advertiserRegisterSubmitBtn:hover{
    cursor: pointer;
    opacity: .9;
}
#alertWrapper>div>div>h3 {
    font-family: "Inter-Light";
}
#alertWrapper>div>svg{
    display: none;
}
#alertWrapper>div>div>button{
    float: right;
}
.leftChevronAdvertiserDetails {
    height: 50px;
    width: 50px;
    display: inline-block;
    padding-left: 10px;
    padding-top: 10px;
}

.leftChevronAdvertiserDetails:hover {
    cursor: pointer;
}
.backContainer{
    display: block;
    position: relative;

}
.advertiserCompanyTitle{
    font-family: 'Inter-Medium';
    font-size: 40px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 20px;
}
.tabTitle {
    font-family: "Inter-Medium";
font-size: 35px;
display: block;

}

.userDetailsContainer {
    padding-left: 10px;
}
.userDetailsItem {
    width: 45%;
    display: inline-block;
    margin: 5px;
    margin-left: 15px;

}

.userDetailsTitle {
    font-family: "Inter-Medium";
    color: rgb(93, 93, 93);
    display: block;

}

.userDetailsData {
    font-family: "Inter-Medium";
    display: block;
}

.saveBtnAdvertUserDetails {
    display: block;
    border: none;
    outline: none;
    font-family: "Inter-Bold";
    height: 40px;
    min-width: 200px;
    font-size: 15px;
    border-radius: 2px;
    background-color: black;
    color: white;
}
#panel\:r2\:1>table>tbody>tr:nth-child(1)>td:nth-child(3)>div {
    display: inline-block !important;
}
@media (max-width: 801px) {

.advertiserRegisterSubmitBtn {
    display: block;
    width: 70%;
}
}